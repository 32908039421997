import { List, ListItem, makeStyles, Typography } from "@material-ui/core";
import {Article} from "../../shared/Article";
import {Title} from "../../shared";

let useStyles = makeStyles((theme) => ({
  articleHeading: {
    padding: theme.spacing(2, 2, 1, 2),
    fontFamily: theme.fontFamily.heading,
    color: theme.colors.primaryNavy,
    textTransform: 'uppercase',
  },
  contentItem: {
    padding: theme.spacing(2),
    display: "flex",
  },
  contentLabel: {
    textTransform: "uppercase",
    fontSize: '1rem',
    width: 176,
    marginRight: theme.spacing(2),
    color: theme.colors.primaryNavy
  },
  contentValue: {
    flex: 1,
    textTransform: "capitalize",
    fontFamily: theme.fontFamily.robotoCondensed,
    color: theme.colors.primaryNavy
  },
  contentValue2: {
    flex: 1,
    fontFamily: theme.fontFamily.robotoCondensed,
    color: theme.colors.primaryNavy
  },
}));

const BasicInfo = ({ user = {} }) => {
  let classes = useStyles();

  return (
    <Article>
      <div className={classes.articleHeading}>
        <Title title='Personal Info'/>
      </div>
      <List>
        <ListItem className={classes.contentItem}>
          <Typography
            color="textSecondary"
            className={classes.contentLabel}
          >
            Name
          </Typography>
          <Typography variant="body1" className={classes.contentValue}>
            {user.full_name}
          </Typography>
        </ListItem>
        <ListItem className={classes.contentItem}>
          <Typography
            color="textSecondary"
            className={classes.contentLabel}
          >
            Email
          </Typography>
          <Typography variant="body1" className={classes.contentValue2}>
            {user.email}
          </Typography>
        </ListItem>
        <ListItem className={classes.contentItem}>
          <Typography
            color="textSecondary"
            className={classes.contentLabel}
          >
            Team
          </Typography>
          <Typography variant="body1" className={classes.contentValue2}>
            {user.team}
          </Typography>
        </ListItem>
        {
          user.sport !== "" ?
            <ListItem className={classes.contentItem} style={{display: user.team === "MM-DDI" ? "none" : "flex"}}>
              <Typography
                color="textSecondary"
                className={classes.contentLabel}
              >
                Sport
              </Typography>
              <Typography variant="body1" className={classes.contentValue2}>
                {user.sport}
              </Typography>
            </ListItem>
            :
            ""
        }
      </List>
    </Article>
  );
};

export default BasicInfo;
