import apiHandler, {getAuthHeader} from "./apiHandler";

export const login = async (credentials) => {
  try {
    const response = await apiHandler.post("/auth/login", credentials);

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    return {
      accessToken: response.data.info.access_token,
      refreshToken: response.data.info.refresh_token,
      user: response.data.info.current_user,
    };
  } catch (err) {
    throw new Error(err);
  }
};

export const getNotifications = async () => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get("/load-notifications/Unread", {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }

    const notifications = response.data["info"]["notifications"];

    let unread = [];
    let data = [];

    if (notifications.length > 0) {
      data = notifications.reduce((obj, item) => {
        obj[item._id] = {
          id: item._id,
          title: item.title,
          content: item.content,
          createdOn: item.created_on,
          status: item.status,
          userId: item.userId,
        };

        if (item.status === "Unread") {
          unread.push(item._id);
        }

        return obj;
      }, {});
    }

    return {
      notifications: data,
      unread,
    };
  } catch (err) {
    throw new Error(err);
  }
};

export const markNotificationsAsRead = async () => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get("/mark-notifications/Read", {
      headers,
    });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const markNotificationByIdAsRead = async (notificationId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/mark_notification/${notificationId}/Read`, { headers });

    if (response.data.status !== "Success") {
      const err = new Error(response.data.notification);
      return Promise.reject(err);
    }
  } catch (err) {
    throw new Error(err);
  }
};

const account = {
  login,
  getNotifications,
  markNotificationsAsRead,
  markNotificationByIdAsRead,
};

export default account;
