import {Box, FormLabel, Grid, Typography} from "@material-ui/core";
import {Field} from "formik";
import {TextField} from "formik-material-ui";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import ClearIcon from "@material-ui/icons/Clear";
import React, {useEffect, useState} from "react";
import formStyles from "../stylessheets/formStyles"

const IconAndLabels = ({
    labels,
    setLabels,
    setFieldValue,
    value,
    iconString,
    setIconString,
    width
}) => {
    const classes = formStyles();
    const [iconName, setIconName] = useState('');
    const [showIcon, setShowIcon] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [initialIcon, setInitialIcon] = useState('')

    useEffect(() => {
        if (iconString.length > 0) {
            setShowIcon(iconString);
            setInitialIcon(iconString);
        }
    }, []);

    const handleUpdateLabels = (value, setFieldValue) => {
        if (value) {
            setLabels([...labels, value]);
            setFieldValue('label', '')
        }
    };

    const handleEditLabel = (index, setFieldValue) => {
        const updateLabel = labels.filter((_, i) => i === index);
        setFieldValue('label', updateLabel);
        const updatedLabels = labels.filter((_, i) => i !== index);
        setLabels(updatedLabels)
    };

    const handleRemoveLabel = (index) => {
        const updatedLabels = labels.filter((_, i) => i !== index);
        setLabels(updatedLabels);
    };

    const handleIconUpload = (event) => {
        const file = event.target.files[0];

        const fileSizeKB = file.size / 1024;
        const fileType = file.type;

        if ((fileType !== 'image/svg+xml' && fileSizeKB > 200) || (fileType === 'image/svg+xml' && fileSizeKB > 200)) {
            setErrorMsg('Icon is larger than 200kbs');
            setIconName('');
            setIconString('');
            if (iconString !== '') {
                setShowIcon(iconString);
            } else {
                setShowIcon(null);
            }
            return;
        }

        setIconName(file.name);

        const previewIcon = URL.createObjectURL(file);
        setShowIcon(previewIcon);
        setErrorMsg('');

        const reader = new FileReader();
        reader.onloadend = () => {
            setIconString(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleRemoveIcon = () => {
        setIconName('');
        setIconString('');
        if (initialIcon !== '') {
            setShowIcon(initialIcon);
        } else {
            setShowIcon(null);
        }
    };

    const handleIconDelete = () => {
        setShowIcon(null);
        setIconString('');
    };

    return (
        <Grid item xs={12}>
            <div className={classes.formCard}>
                <Typography className={classes.cardTitle}>Labels</Typography>
                <div className={classes.labelsInput}>
                    <Field
                        name="label"
                        label="Labels"
                        placeholder="Labels"
                        component={TextField}
                        variant="outlined"
                        style={{ width: width ? `calc(${width} + 40%)` : '65%' }}
                    />
                    <button
                        className={classes.btnPrimary}
                        type="button"
                        onClick={() => handleUpdateLabels(value, setFieldValue)}
                    >
                        Add
                    </button>
                </div>
                <div className={classes.flexStart} >
                    {
                        labels && labels.length > 0 ? (
                            labels.map((label, index) => (
                                <div key={index}
                                     className={`${classes.labelItem} ${classes.labelItemSpacing}`}>
                                    <div style={{display: 'flex'}}>
                                        <Box
                                            onClick={() => handleEditLabel(index, setFieldValue)}
                                            style={{marginRight: 4}}>
                                            <CreateOutlinedIcon style={{
                                                fontSize: '0.9rem',
                                                marginTop: 1
                                            }}/>
                                        </Box>
                                        {label}
                                    </div>
                                    <Box
                                        onClick={() => handleRemoveLabel(index)}
                                        style={{marginTop: 'auto'}}>
                                        <ClearIcon style={{fontSize: '0.9rem'}} />
                                    </Box>
                                </div>
                            ))) : null
                    }
                </div>
                <div className={classes.iconContainer} style={{ width: width ? width : '70%' }}>
                    <Typography className={classes.cardTitle}>Trait Icon</Typography>
                    <div style={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: "space-between"
                    }}>
                        {
                            showIcon === null ? (
                                <div className={classes.noIcon}/>
                            ) : (
                                <div style={{display: "flex"}}>
                                    <img src={showIcon} alt='Icon'
                                         width={120} height={120}/>
                                    <div className={classes.flexStart} >
                                        {
                                            iconName === '' && showIcon ? (
                                                <Box
                                                    onClick={handleIconDelete}
                                                    style={{
                                                        display: 'flex',
                                                    }}>
                                                    <ClearIcon style={{
                                                        fontSize: '1.1rem',
                                                        marginLeft: 15
                                                    }}/>
                                                </Box>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <FormLabel htmlFor='iconUpload' className={classes.iconBtn}>
                            <div style={{marginTop: 8, color: '#ffffff'}}>
                                Upload Icon
                            </div>
                            <input type='file' hidden name='icon'
                                   id='iconUpload'
                                   onChange={handleIconUpload}/>
                        </FormLabel>
                    </div>
                    <div style={{display: 'flex', paddingTop: 10}}>
                        <div style={{display: 'flex'}}>
                            <Typography className={classes.iconName} >
                                {iconName}
                            </Typography>
                            {
                                iconName.length > 0 ? (
                                    <Box onClick={handleRemoveIcon}>
                                        <ClearIcon style={{
                                            fontSize: '1.1rem',
                                            marginLeft: 15
                                        }}/>
                                    </Box>
                                ) : null
                            }
                        </div>
                        {
                            errorMsg.length > 0 ? (
                                <Typography
                                    className={classes.error}>{errorMsg}</Typography>
                            ) : (
                                <>
                                    {
                                        iconName.length === 0 ? (
                                            <Typography>
                                                Upload a square icon (e.g., 512x512px) and keep the file size under 200KB for best results.
                                            </Typography>
                                        ) : null
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </Grid>
    )
}

export default IconAndLabels;