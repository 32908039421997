import {accountApi, userApi} from "../../../api";
import {showErrorMessage, showSuccessMessage} from "../../shared/Snackbar";
import * as types from "./accountTypes";
import {getAccountsList} from "../../../api/dashboard";

const getNotifications = ( handleFail = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_NOTIFICATIONS,
      });

      const { notifications, unread } = await accountApi.getNotifications();

      dispatch({
        type: types.GET_NOTIFICATIONS_SUCCESS,
        payload: {
          notifications,
          unread,
        },
      });

    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_NOTIFICATIONS_FAILED,
      });

      handleFail();
    }
  };
};

const markNotificationsAsRead = (handleSuccess = (f) => f) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.REQUEST_MARK_NOTIFICATIONS_AS_READ,
      });

      await accountApi.markNotificationsAsRead();

      let state = getState();

      dispatch({
        type: types.MARK_NOTIFICATIONS_AS_READ_SUCCESS,
        payload: {
          unreadIds: state.account.notifications.unreadIds,
        },
      });

      dispatch(getNotifications());

      handleSuccess();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.MARK_NOTIFICATIONS_AS_READ_FAILED,
      });
    }
  };
};

const markNotificationByIdAsRead = (notificationId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.MARK_NOTIFICATION_BY_ID_AS_READ_REQUEST,
            });

            await accountApi.markNotificationByIdAsRead(notificationId);
            dispatch({
                type: types.MARK_NOTIFICATION_BY_ID_AS_READ_SUCCESS,
                payload: { notificationId },
            });

            dispatch(showSuccessMessage("Notification marked as read"));
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.MARK_NOTIFICATION_BY_ID_AS_READ_FAILED,
                payload: "Failed to mark notification as read",
            });
        }
    };
};

const signIn = (data, successCb = (f) => f, failCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_SIGNIN,
      });

      const response = await accountApi.login(data);

      dispatch({
        type: types.SIGNIN_SUCCESS,
        payload: response,
      });

      const { accessToken, refreshToken, user } = response;
      successCb(accessToken, refreshToken, user.account_type);
    } catch (err) {
      dispatch({
        type: types.SIGNIN_FAILED,
        payload: {
          error: err.message,
        },
      });
      failCb();
    }
  };
};

const signUp = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_SIGNUP,
      });

      await userApi.createUser(data);

      dispatch({
        type: types.SIGNUP_SUCCESS,
      });
        dispatch(getAccountsList());
    } catch (err) {
      dispatch({
        type: types.SIGNUP_FAILED,
      });
    }
  };
};

const forgotPassword = (data, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_RESET_PASSWORD,
      });

      const response = await userApi.resetPassword(data);

      if (response.status === "Error") {
        dispatch({
          type: types.RESET_PASSWORD_FAILED,
          payload: {
            error: response.notification,
          },
        });
      } else {
        dispatch({
          type: types.RESET_PASSWORD_SUCCESS,
          payload: response,
        });
        successCb();
      }
    } catch (err) {
      dispatch({
        type: types.RESET_PASSWORD_FAILED,
        payload: {
          error: err.message,
        },
      });
    }
  };
};

const verify = (data, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_VERIFY_ACCOUNT,
      });

      const response = await userApi.confirmEmail(data);

      if (response.status === "Error") {
        dispatch({
          type: types.VERIFY_ACCOUNT_FAILED,
          payload: {
            error: response.notification,
          },
        });
      } else {
        dispatch({
          type: types.VERIFY_ACCOUNT_SUCCESS,
          payload: {},
        });
        successCb();
        localStorage.setItem("@acccessToken", response.info.access_token);
      }
    } catch (err) {
      dispatch({
        type: types.VERIFY_ACCOUNT_FAILED,
      });
    }
  };
};

const resendVerification = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_VERIFY_EMAIL,
        payload: {
          email: data.email,
        },
      });

      const response = await userApi.resendVerification(data);

      if (response.status === "Error") {
        dispatch({
          type: types.RESEND_VERIFICATION_FAILED,
          payload: {
            error: response.notification,
          },
        });
      } else {
        dispatch({
          type: types.RESEND_VERIFICATION_SUCCESS,
          payload: response,
        });
      }
    } catch (err) {
      dispatch({
        type: types.RESEND_VERIFICATION_FAILED,
        payload: {
          error: err.message,
        },
      });
    }
  };
};

const changePassword = (data, successCb) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_CHANGE_PASSWORD,
      });

      const response = await userApi.changePassword(data);
      if (response.status === "Error") {
        dispatch({
          type: types.CHANGE_PASSWORD_FAILED,
          payload: {
            error: response.notification,
          },
        });
      } else {
        dispatch({
          type: types.CHANGE_PASSWORD_SUCCESS,
        });

        successCb();
      }
    } catch (err) {
      dispatch({
        type: types.CHANGE_PASSWORD_FAILED,
        payload: {
          error: "Request Failed",
        },
      });
    }
  };
};

const getUserProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_PROFILE,
      });
      const response = await userApi.getProfile();
      dispatch({
        type: types.GET_PROFILE_SUCCESS,
        payload: response,
      });
    } catch (err) {
      dispatch({
        type: types.GET_PROFILE_FAILED,
        payload: err,
      });
    }
  };
};

const appSignOut = () => {
  return {
    type: types.APP_SIGNOUT,
  };
};

const getUserTypes = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_USER_TYPES,
      });

      const response = await userApi.getUserTypes();

      dispatch({
        type: types.GET_USER_TYPES_SUCCESS,
        payload: {
          userTypes: response,
        },
      });
    } catch (err) {
      dispatch({
        type: types.GET_USER_TYPES_FAILED,
      });
    }
  };
};

const resetAccountCreationError = () => {
  return {
    type: types.RESET_ACCOUNT_CREATION_ERROR,
  };
};

const setAppAlert = (message) => {
  return {
    type: types.SET_APP_ALERT,
    payload: {
      message,
    },
  };
};

const resetAppAlert = () => {
  return {
    type: types.RESET_APP_ALERT,
  };
};

const resetLoginError = () => {
  return {
    type: types.RESET_LOGIN_ERROR,
  };
};

const selectedAccountById = (user_id, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.GET_ACCOUNT_BY_EMAIL_REQUEST,
      });

      let account = await userApi.getAccountById(user_id);
      dispatch({
        type: types.GET_ACCOUNT_BY_EMAIL_SUCCESS,
        payload: account,
      });

      dispatch(showSuccessMessage('Successfully got account'));
      successCb();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_ACCOUNT_BY_EMAIL_FAILED,
        payload: 'Failed to get account'
      });
    }
  };
};

const resetAccountUpdateError = () => {
  return {
    type: types.RESET_ACCOUNT_UPDATE_ERROR
  }
};

const updateUserAccount = (email, data, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.UPDATE_ACCOUNT_BY_EMAIL_REQUEST,
        payload: email,
      });

      const user = await userApi.updateUserAccount(email, data);
      dispatch({
        type: types.UPDATE_ACCOUNT_BY_EMAIL_SUCCESS,
        payload: {
          email,
          data: user,
        },
      });

      dispatch(showSuccessMessage('Successfully updated user'));
      successCb();
    } catch (err) {
      dispatch(showErrorMessage('Failed to update user'));
      dispatch({
        type: types.UPDATE_ACCOUNT_BY_EMAIL_FAILED,
        payload: 'Failed to update user',
      });
    }
  };
};

const resetPasswordByAdmin = (email) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.RESET_PASSWORD_BY_ADMIN_REQUEST,
      });

      const passwordReset = await userApi.resetPasswordByAdmin(email);
      dispatch({
        type: types.RESET_PASSWORD_BY_ADMIN_SUCCESS,
        payload: passwordReset,
      });
    } catch (err) {
      dispatch(showErrorMessage('Failed to request a reset'));
      dispatch({
        type: types.RESET_PASSWORD_BY_ADMIN_FAILED,
        payload: 'Failed to request a password reset',
      });
    }
  }
};

const deleteAccountByAdmin = (user_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.DELETE_ACCOUNT_BY_ADMIN_REQUEST,
      });

      await userApi.deleteAccountByAdmin(user_id);
      dispatch({
        type: types.DELETE_ACCOUNT_BY_ADMIN_SUCCESS,
      });

      dispatch(showSuccessMessage('Successfully deleted account'));
    } catch (err) {
      dispatch(showErrorMessage('Did not delete user'));
      dispatch({
        type: types.DELETE_ACCOUNT_BY_ADMIN_FAILED,
        payload: "Failed to delete the user's account",
      });
    }
  };
};

const accountActions = {
  signIn,
  signUp,
  forgotPassword,
  verify,
  resendVerification,
  changePassword,
  getUserProfile,
  appSignOut,
  getUserTypes,
  resetAccountCreationError,
  setAppAlert,
  resetAppAlert,
  resetLoginError,
  getNotifications,
  markNotificationsAsRead,
  markNotificationByIdAsRead,
  selectedAccountById,
  resetAccountUpdateError,
  updateUserAccount,
  resetPasswordByAdmin,
  deleteAccountByAdmin,
};

export default accountActions;
