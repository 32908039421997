import React, {useContext, useEffect, useState} from "react";
import {
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Field } from "formik";
import {connect} from "react-redux";
import { ActionContext } from "../../../contexts";
import { Autocomplete } from "formik-material-ui-lab";
import MuiTextField from "@material-ui/core/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

let useStyles = makeStyles((theme) => ({
    heading: {
        maxWidth: 480,
        lineHeight: "60px",
        color: theme.palette.text.primary,
        fontFamily: theme.fontFamily.heading,
    },
    form: {
        paddingTop: theme.spacing(1),
        maxWidth: '100%',
    },
}));

let CreatePlayerBackground = (props) => {
  let classes = useStyles();
  const {
    accountState,
    errors,
    touched,
    countriesData,
    stateData,
    values,
    allConferences,
    allConferenceByType,
    sportPositions,
    allSports,
    sportByConference,
    allUnits,
    unitsBySports,
  } = props;
  let actions = useContext(ActionContext);
  let { sportsActions, conferenceActions } = actions;
  const [finalPosition, setFinalPosition] = useState([]);
  const current_team = accountState.team !== "MM-DDI";

  useEffect(() => {
      sportsActions.getAllUnits();
      if (values.conferenceType !== "") {
          conferenceActions.getConferenceByType(values.conferenceType)
      }
  }, []);

  useEffect(() => {
      if (values.conference !== "") {
          let conference = allConferenceByType && allConferenceByType.find(conference => conference.name === values.conference);
          if (conference) {
              conferenceActions.getSportsByConferenceId(conference._id);
          }
      } else {
          conferenceActions.resetSportsByConferenceId();
      }
  }, [allConferenceByType, conferenceActions, values.conference]);

  const conferenceTypes = ["Professional", "Collegiate"];

  const relationshipTypes = ["Single", "Married", "Unknown"];

  const genderTypes = ["Men's Sports", "Women's Sport"];

  const raceTypes = [
      "American Indian/Alaska Native",
      "Asian",
      "Black/African American",
      "Hispanic/Latino",
      "Native Hawaiian/Pacific Islander",
      "White",
      "Unknown/Prefer Not To Identify",
  ];

  const athleteTypes = ["Roster", "Recruit"];

  let conferences = allConferences.reduce((obj, item) => {
      obj[item.name] = item.name
      return obj;
  }, {});

  let conferenceByType = allConferenceByType.reduce((obj, item) => {
      obj[item.name] = item.name
      return obj;
  }, {});

  let finalConferences = allConferenceByType.length > 0  ? conferenceByType : conferences;

  let allSportsList = allSports.map(sport => sport.name);

  let sportByConferenceList = {
      "name": sportByConference.name
  };

  let finalSport = sportByConference.length === 0 ? allSportsList : sportByConferenceList;

  let units = allUnits.map(u => u.unit);

  let unitsBySportId = unitsBySports.map(u => u.unit);

  let finalUnits = unitsBySports.length === 0 ? units : unitsBySportId;

  const handleCountryName = (value) => {
    values.homeCountry = value;
    conferenceActions.getStatesByCountryName(value);
  };

  const handleConferenceType = (value) => {
      values.conferenceType = value;
      conferenceActions.getConferenceByType(value);
  };

  const handleConference = (value) => {
      values.conference = value;
      values.sport = "";
      values.unit = "";
      values.positions = "";
      if (value !== null) {
          let conference = allConferences && allConferences.find(conference => conference.name === value);
          conferenceActions.getSportsByConferenceId(conference._id);
      } else {
          conferenceActions.resetSportsByConferenceId();
      }
  };

  const handleSportChange = (value) => {
      values.sport = value;
      values.unit = "";
      values.positions = "";
      if (value !== null) {
          let sport = allSports && allSports.find(sport => sport.name === value);
          if (sport) {
              sportsActions.getSportUnitDetails(sport._id);
              sportsActions.getSportPositionDetails(sport._id);
          }
      } else {
          sportsActions.resetUnitsApi();
          sportsActions.resetSportsApi();
      }
  };

  const handleUnitChange = (value) => {
      values.unit = value;
      values.positions = "";
      if (sportPositions) {
          setFinalPosition(sportPositions
              .filter(unit => unit.unit_name === value)
              .map(position => position.position));
      } else {
          sportsActions.resetSportsApi();
          sportsActions.resetUnitsApi();
      }
  };

  const handlePositionChange = (value) => {
      values.positions = value;
  };

  const handleRelationshipStatus = (value) => {
      values.relationship = value;
  };

  const handleSelectedGender = (value) => {
      values.gender = value;
  };

  const handleSelectedRaceEthnicity = (value) => {
      values.race = value;
  };

  const handleAthleteType = (value) => {
      values.athleteType = value;
  };

  return (
    <React.Fragment>
      <Typography variant="h5" className={classes.heading}>
        Let's get some background info
      </Typography>
      <div className={classes.form}>
        <Grid container spacing={3}>
          <Grid item lg={6} sm={12}>
            <Field
              name="homeCountry"
              required
              component={Autocomplete}
              options={Object.keys(countriesData)}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Home Country"
                    placeholder="Home Country"
                    error={touched["homeCountry"] && !!errors["homeCountry"]}
                    helperText={
                      <FormHelperText style={{ color: "red" }}>
                        {errors["homeCountry"]}
                      </FormHelperText>
                    }
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleCountryName(value);
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="homeState"
              component={Autocomplete}
              options={Object.keys(stateData) || {}}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Home State"
                    placeholder="Home State"
                    error={touched["homeState"] && !!errors["homeState"]}
                    helperText={errors["homeState"]}
                    {...props}
                  />
                );
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item sm={12}>
            <Field
              name="hometown"
              label="Hometown"
              placeholder="Hometown"
              component={TextField}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
                name="gender"
                required
                component={Autocomplete}
                options={genderTypes}
                renderInput={(props) => {
                  return (
                      <MuiTextField
                          variant="outlined"
                          label="Gender"
                          placeholder="Gender"
                          error={
                              touched["gender"] && !!errors["gender"]
                          }
                          helperText={
                            <FormHelperText style={{ color: "red" }}>
                              {errors["gender"]}
                            </FormHelperText>
                          }
                          required
                          {...props}
                      />
                  );
                }}
                onChange={(e, value) => {
                  handleSelectedGender(value);
                }}
                renderOption={(value, { inputValue }) => {
                  const matches = match(value, inputValue);
                  const parts = parse(value, matches);

                  return (
                      <div>
                        {parts.map((part, index) => (
                            <span
                                key={index}
                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                            >
                        {part.text}
                      </span>
                        ))}
                      </div>
                  );
                }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
                name="race"
                required
                component={Autocomplete}
                options={raceTypes}
                renderInput={(props) => {
                  return (
                      <MuiTextField
                          variant="outlined"
                          label="Race/Ethnicity"
                          placeholder="Race/Ethnicity"
                          error={
                              touched["race"] && !!errors["race"]
                          }
                          helperText={
                            <FormHelperText style={{ color: "red" }}>
                              {errors["race"]}
                            </FormHelperText>
                          }
                          required
                          {...props}
                      />
                  );
                }}
                onChange={(e, value) => {
                  handleSelectedRaceEthnicity(value);
                }}
                renderOption={(value, { inputValue }) => {
                  const matches = match(value, inputValue);
                  const parts = parse(value, matches);

                  return (
                      <div>
                        {parts.map((part, index) => (
                            <span
                                key={index}
                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                            >
                        {part.text}
                      </span>
                        ))}
                      </div>
                  );
                }}
            />
          </Grid>

            <Grid item sm={12}>
                <Field
                    name="relationship"
                    component={Autocomplete}
                    options={relationshipTypes}
                    renderInput={(props) => {
                        return (
                            <MuiTextField
                                variant="outlined"
                                label="Relationship Status"
                                placeholder="Relationship Status"
                                error={
                                    touched["relationship"] && !!errors["relationship"]
                                }
                                helperText={
                                    <FormHelperText style={{ color: "red" }}>
                                        {errors["relationship"]}
                                    </FormHelperText>
                                }
                                required
                                {...props}
                            />
                        );
                    }}
                    onChange={(e, value) => {
                        handleRelationshipStatus(value);
                    }}
                    renderOption={(value, { inputValue }) => {
                        const matches = match(value, inputValue);
                        const parts = parse(value, matches);

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                                    >
                        {part.text}
                      </span>
                                ))}
                            </div>
                        );
                    }}
                />
            </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="conferenceType"
              required
              component={Autocomplete}
              options={conferenceTypes}
              disabled={values.conferenceType !== "" && current_team}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="League"
                    placeholder="League"
                    error={
                      touched["conferenceType"] && !!errors["conferenceType"]
                    }
                    helperText={
                      <FormHelperText style={{ color: "red" }}>
                        {errors["conferenceType"]}
                      </FormHelperText>
                    }
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleConferenceType(value);
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

            <Grid item lg={6} sm={12}>
                <Field
                    name="conference"
                    required
                    component={Autocomplete}
                    options={Object.keys(finalConferences)}
                    disabled={values.conference !== "" && current_team}
                    renderInput={(props) => {
                        return (
                            <MuiTextField
                                variant="outlined"
                                label="Conference"
                                placeholder="Conference"
                                error={touched["conference"] && !!errors["conference"]}
                                helperText={
                                    <FormHelperText style={{ color: "red" }}>
                                        {errors["conference"]}
                                    </FormHelperText>
                                }
                                required
                                {...props}
                            />
                        );
                    }}
                    onChange={(e, value) => {
                        handleConference(value);
                    }}
                    renderOption={(value, { inputValue }) => {
                        const matches = match(value, inputValue);
                        const parts = parse(value, matches);

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                                    >
                        {part.text}
                      </span>
                                ))}
                            </div>
                        );
                    }}
                />
            </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="sport"
              required
              component={Autocomplete}
              options={Object.values(finalSport)}
              disabled={values.sport !== "" && current_team}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Sport"
                    placeholder="Sport"
                    error={touched["sport"] && !!errors["sport"]}
                    helperText={
                      <FormHelperText style={{ color: "red" }}>
                        {errors["sport"]}
                      </FormHelperText>
                    }
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleSportChange(value);
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Field
              name="unit"
              required
              component={Autocomplete}
              options={finalUnits}
              renderInput={(props) => {
                return (
                  <MuiTextField
                    variant="outlined"
                    label="Unit"
                    placeholder="Unit"
                    error={touched["unit"] && !!errors["unit"]}
                    helperText={
                      <FormHelperText style={{ color: "red" }}>
                        {errors["unit"]}
                      </FormHelperText>
                    }
                    required
                    {...props}
                  />
                );
              }}
              onChange={(e, value) => {
                handleUnitChange(value);
              }}
              renderOption={(value, { inputValue }) => {
                const matches = match(value, inputValue);
                const parts = parse(value, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>

            <Grid item lg={6} sm={12}>
                <Field
                    name="position"
                    required
                    component={Autocomplete}
                    options={finalPosition}
                    renderInput={(props) => {
                        return (
                            <MuiTextField
                                variant="outlined"
                                label="Position"
                                placeholder="Position"
                                error={touched["position"] && !!errors["position"]}
                                helperText={
                                    <FormHelperText style={{ color: "red" }}>
                                        {errors["position"]}
                                    </FormHelperText>
                                }
                                required
                                {...props}
                            />
                        );
                    }}
                    onChange={(e, value) => {
                        handlePositionChange(value);
                    }}
                    renderOption={(value, { inputValue }) => {
                        const matches = match(value, inputValue);
                        const parts = parse(value, matches);

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                                    >
                        {part.text}
                      </span>
                                ))}
                            </div>
                        );
                    }}
                />
            </Grid>

          <Grid item lg={6} sm={12}>
            <Field
                name="athleteType"
                required
                component={Autocomplete}
                options={athleteTypes}
                renderInput={(props) => {
                  return (
                      <MuiTextField
                          variant="outlined"
                          label="Athlete Type"
                          placeholder="Athlete Type"
                          error={
                              touched["athleteType"] && !!errors["athleteType"]
                          }
                          helperText={
                            <FormHelperText style={{ color: "red" }}>
                              {errors["athleteType"]}
                            </FormHelperText>
                          }
                          required
                          {...props}
                      />
                  );
                }}
                onChange={(e, value) => {
                  handleAthleteType(value);
                }}
                renderOption={(value, { inputValue }) => {
                  const matches = match(value, inputValue);
                  const parts = parse(value, matches);

                  return (
                      <div>
                        {parts.map((part, index) => (
                            <span
                                key={index}
                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                            >
                        {part.text}
                      </span>
                        ))}
                      </div>
                  );
                }}
            />
          </Grid>

        </Grid>
      </div>
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    countriesData: state.conference.allCountries,
    stateData: state.conference.allStateByCountry,
    sportPositions: state.sports.sportPositions,
    allConferences: state.conference.allConferenceList,
    allConferenceByType: state.conference.allConferenceByType,
    allSports: state.sports.sportsListData,
    sportByConference: state.conference.sportByConference,
    allUnits: state.sports.sportUnits,
    unitsBySports: state.sports.unitsBySportId,
    accountState: state.account.accountData,
  };
};
export default connect(mapStateToProps)(CreatePlayerBackground);
